import React from "react"

const MailChimp = () => {
  return (
    <>
      <div id="mc_embed_shell">
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">{`
        #mc_embed_signup{clear:left;}
        #mc_embed_signup form{margin: 0;}
        #mc_embed_signup form label {font-size: 14px}
        #mce-responses {padding: 0; width: 100%; margin: 0}
        #mc_embed_signup div#mce-responses { width: 100%; padding: 0; margin: 0 0 15px 0;}
        #mc_embed_signup div.response {padding: 0; font-size: 14px; margin: 0 0 15px 0; width: 100%; font-weight: 400;}
      `}</style>
        <div id="mc_embed_signup">
          <form
            action="https://obviouslee.us17.list-manage.com/subscribe/post?u=c761094f94a8165dc7aeec79e&amp;id=f3b203d336&amp;f_id=00d3cde3f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input type="email" name="EMAIL" />
              </div>
              <div hidden="">
                <input type="hidden" name="tags" value="8330807" />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{
                  position: "absolute",
                  left: "-5000px",
                }}
              >
                <input
                  type="text"
                  name="b_c761094f94a8165dc7aeec79e_f3b203d336"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  defaultValue="Subscribe"
                />
              </div>
            </div>
          </form>
          <div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>
        </div>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script type="text/javascript">
        {`
          (function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
        `}
      </script>
    </>
  )
}

export default MailChimp
